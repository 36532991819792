import axios from 'axios'
import Upload from 'gcs-browser-upload'
import regeneratorRuntime from "regenerator-runtime";
import "regenerator-runtime/runtime.js";
import utils from './utils.js'

var cancellationToken;
var upload;

async function post(action, requestData, useRecaptchaToken = false, timeout){
    //cancellationToken = source;
    let headers = {};
    if(useRecaptchaToken) {
      let token = await utils.getRecaptchaToken(action);
      headers['reCaptchaToken'] = token;
    }
    const {requestConfig, source} = initPostParams(headers, timeout);
    return axios.post(`${process.env.HOST}/${action}`, requestData, requestConfig);
}

async function postExternal(url, action, requestData, useRecaptchaToken = false, timeout){
  //cancellationToken = source;
  let headers = {};
  if(useRecaptchaToken) {
    let token = await utils.getRecaptchaToken(action);
    headers['reCaptchaToken'] = token;
  }
  const {requestConfig, source} = initPostParams(headers, timeout);
  return axios.post(`${url}/${action}`, requestData, requestConfig);
}

async function get(action, requestData, headers){
  let axiosParams = { params: requestData }
  var requestConfig = {
    withCredentials: true,
    headers: headers
  };
  return axios.get(`${process.env.HOST}/${action}`, axiosParams, requestConfig);
}

async function getExternalUrl(url, headers){
  let axiosParams = {};
  var requestConfig = {
    headers: headers
  };
  return axios.get(url, axiosParams, requestConfig);
}

 async function uploadFile(file, updateMbUploaded, updateUploadProgressInPercentage){
  let uri = await axios.post(`${process.env.HOST}/uri`, {name: file.name});
  var chunkSize = 262144*2*2;
  upload = new Upload({
    id: `${file.name}_${Date.now()}`,
    url: uri.data,
    file: file,
    chunkSize: chunkSize,
    onChunkUpload: (info) => {
      console.log('progress: ' + (info.uploadedBytes / info.totalBytes)*100 + "%")
      updateMbUploaded(info.uploadedBytes);
      updateUploadProgressInPercentage((info.uploadedBytes / info.totalBytes)*100);
    }
  })

  

  try {
    await upload.start()
    console.log('Upload complete!')
  } catch (e) { 
    updateMbUploaded(file.size);
    updateUploadProgressInPercentage(100);
    return `https://storage.googleapis.com/filedriver-files-storage/${encodeURIComponent(file.name)}`;
  } finally {
    upload = null
  }
}

async function getSignedUrl(uris, updateUploadErrorOccurred, uriType) {
  try {
    console.log('getting reCaptcha token');
    let token = await utils.getRecaptchaToken('/uri');
    console.log('reCaptcha token fetched successfully ' + token);
    const {requestConfig} = initPostParams({'reCaptchaToken': token});
    console.log(`Sending request to server: ${process.env.HOST}/uri`);
    let uri = await axios.post(`${process.env.HOST}/uri`, {filesUri: uris, uriType: uriType}, requestConfig);
    console.log('signed url fetched successfully ' + uri);
    return uri;
  } catch (error) {
    console.log("error getting signed url " + error);
    updateUploadErrorOccurred(true);
    return null;
  }
}

async function uploadFiles(filesBlob, uriFileName, uriType, updateMbUploaded, updateUploadProgressInPercentage, updateIsUploadInProgress, updateIsFileUploadPreparationInProgress, updateUploadErrorOccurred, onInitCb, onChunkUploadCb, bps){
  try {
  updateIsUploadInProgress && updateIsUploadInProgress(true);
  updateUploadProgressInPercentage && updateUploadProgressInPercentage(0);
  let uri = null;
  let lastChunkUploadDuration = 0;
  if(filesBlob.signedUrl) {
    uri = filesBlob.signedUrl;
  } else {
     let res = await getSignedUrl([uriFileName], updateUploadErrorOccurred, uriType);
     uri = res.data;
  }
  if(uri != null) {
    let chunkUploadedIndex = 0;
    //var chunkSize = 262144*2*2;
    var chunkSize = 262144 * 2 * 2 * 2 * 2 * 2;
    upload = new Upload({
      id: `${uriFileName}_${Date.now()}`,
      url: uri,
      file: filesBlob,
      chunkSize: chunkSize,
      onChunkUpload: (info) => {
        if (lastChunkUploadDuration === 0) { //execute only on the first chunk
          updateIsFileUploadPreparationInProgress && updateIsFileUploadPreparationInProgress(false); //update file preparation state to complete in the first chunk
          updateUploadProgressInPercentage && updateUploadProgressInPercentage((info.uploadedBytes / info.totalBytes)*100);
          onChunkUploadCb && onChunkUploadCb(info.uploadedBytes, chunkUploadedIndex * chunkSize, null, info.isLastChunk);
        }
        console.log('progress: ' + (info.uploadedBytes / info.totalBytes)*100 + "%")
        chunkUploadedIndex++;
        lastChunkUploadDuration = info.chunkLength / (info.duration / 1000);
        //updateMbUploaded(info.uploadedBytes);
      },
      BeforeChunkUpload: (info) => {
        if(lastChunkUploadDuration > 0) {
        // alert('lastChunkUploadDuration: ' + lastChunkUploadDuration);
        //  alert('info.chunkLength: ' + info.chunkLength);
          let animationDuration = (info.chunkLength / lastChunkUploadDuration) * 1000;
        //  alert('animationDuration ' + animationDuration);
          updateUploadProgressInPercentage && updateUploadProgressInPercentage((info.uploadedBytes / info.totalBytes)*100);
          onChunkUploadCb && onChunkUploadCb(info.uploadedBytes, chunkUploadedIndex * chunkSize, animationDuration);
        }
      }
    })
    onInitCb && onInitCb(upload);
    try {
       await upload.start()
      //console.log('Upload completed!' + e);
      //updateMbUploaded(filesBlob.size);
      updateUploadProgressInPercentage && updateUploadProgressInPercentage(100);
    } catch (e) { 
      updateUploadErrorOccurred && updateUploadErrorOccurred(true);
    } 
  }
}
  finally {
    updateIsFileUploadPreparationInProgress && updateIsFileUploadPreparationInProgress(false);
    updateIsUploadInProgress && updateIsUploadInProgress(false);
    upload = null
  }
}

async function sendChunk(file, uri, i, fileSize){
  var chunks = sliceFile(file, 10);
  var firstChunk = await convertFileToByteArray(chunks[i]);
 // var lastChunk = await convertFileToByteArray(chunks[9]);
  var offset = 0;
  const total = file.size
  const start = offset * firstChunk.byteLength
  const end = offset * firstChunk.byteLength + firstChunk.byteLength - 1
  var requestConfig = {
    headers: {
    //  "Content-Length": chunks[i].size,
      "Content-Type": "text/plain",
      'Content-Range': `bytes ${start}-${end}/${total}`
      //"Content-Range": 'bytes */*',
     // "Content-Range": 'bytes ' + firstChunk[0] + `-${lastChunk[lastChunk.length-1]}/` + fileSize,
     // "Content-Range": 'bytes ' + offset + '-*/' + file.size,
    }
  };
  axios.put(uri, null , requestConfig).then(response => {
    console.log(response.data);
}).catch(error => {
    if(error.response.status === 308 && i < chunks.length){
      sendChunk(chunks[++i]);
    }
    console.log(error.response);
    console.log(error.response.data);
});
 // var res = await axios.put(uri, null , requestConfig);
  return {};
}

function convertFileToByteArray(file){
  return new Promise((resolve, reject) => {
    let fr = new FileReader();
    fr.readAsArrayBuffer(file);
    fr.onload = function(event) {
    console.log(event.target.result);
    let filesByteArray = new Int8Array(event.target.result);
    resolve(filesByteArray);
    };
  });
}

function initUploadParams(uploadProgressCb){
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    var requestConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      cancelToken: source.token,
      onUploadProgress: uploadProgressCb,
      encType: "multipart/form-data"
    };
  
    return {requestConfig, source};
  }

  function initPostParams(headers, timeout){
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    var requestConfig = {
      withCredentials: true,
      cancelToken: source.token,
      headers: headers
    };
    if(timeout) {
      requestConfig.timeout = timeout;
    }
    return {requestConfig, source};
  }
  
  function cancelUpload(){
    if(upload) {
      upload.cancel();
      console.log('Operation canceled by the user');
    }
    /*if(cancellationToken) {
        cancellationToken.cancel('Operation canceled by the user.');
    }*/
  }

  /**
* @param {File|Blob} - file to slice
* @param {Number} - chunksAmount
* @return {Array} - an array of Blobs
**/
function sliceFile(file, chunksAmount) {
  var byteIndex = 0;
  var chunks = [];
    
  for (var i = 0; i < chunksAmount; i += 1) {
    var byteEnd = Math.ceil((file.size / chunksAmount) * (i + 1));
    chunks.push(file.slice(byteIndex, byteEnd));
    byteIndex += (byteEnd - byteIndex);
  }

  return chunks;
}

export default {
    post,
    get,
    cancelUpload,
    uploadFile,
    uploadFiles,
    getSignedUrl,
    getExternalUrl,
    postExternal
}