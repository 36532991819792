/* eslint-disable no-undef */
   import consts from './consts'
   import restUtils from './restUtils'
   import * as html2canvas from "html2canvas";

   function arrayUniqueNames(array) {
    var a = array.concat();
    for(var i=0; i<a.length; ++i) {
        for(var j=i+1; j<a.length; ++j) {
           if(a[i] && a[j]) {
            if(a[i].name === a[j].name) {
               a.splice(j--, 1);
            }  
           }
        }
    }

    return a;
}

    function bytesToSize(bytes, keepFraction) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return keepFraction ? (bytes / Math.pow(1024, i)).toPrecision(4) + ' ' + sizes[i]: Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
 }

 function bytesToKbs(bytes, keepFraction) {
   if (bytes == 0) return '0 Byte';
   return keepFraction ? (bytes / Math.pow(1024, 1)).toPrecision(3) : Math.round(bytes / Math.pow(1024, 1), 2) + ' ' + 'KB';
}

 function snowplowLoaded() {
     if(typeof window !== 'undefined'){
        return window.snowplow;
     }
  }

  function trackFileAdded(eventData) {
   trackSelfDescribedEvent(eventData, 'uploaded_file_context');
  }

  function trackUploadCancelled(eventData) {
   trackSelfDescribedEvent(eventData, 'file_upload_cancelled_event');
  }

  function trackFileRemoved(eventData) {
   trackSelfDescribedEvent(eventData, 'file_removed_event');
  }

  function trackFileDownloaded(eventData) {
   trackSelfDescribedEvent(eventData, 'file_downloaded_event');
  }

  function trackFileUploadStarted(eventData) {
   trackSelfDescribedEvent(eventData, 'file_upload_started_event');
  }

  function trackFileUploadCompleted(eventData) {
   trackSelfDescribedEvent(eventData, 'file_upload_completed_event');
  }

  function trackFormStepChanged(eventData) {
   trackSelfDescribedEvent(eventData, 'form_step_changed_event');
  }

  function trackUserEmailAdded(eventData) {
   trackSelfDescribedEvent(eventData, 'user_email_added_event');
  }

  function trackRecipientAdded(eventData) {
   trackSelfDescribedEvent(eventData, 'recipient_added_event');
  }

  function trackUploadCompleted(eventData) {
   trackSelfDescribedEvent(eventData, 'upload_completed_event');
  }

  function trackWorkQuestionAnsweredEvent(eventData) {
   trackSelfDescribedEvent(eventData, 'work_question_answered_event');
  }

  function trackPageView(pageTitle){
   if(snowplowLoaded()){
      snowplow('trackPageView', pageTitle);
   }
}

  function trackSelfDescribedEvent(eventData, eventSchema){
   if(snowplowLoaded()){
      snowplow('trackSelfDescribingEvent', {
         schema: `iglu:com.filedriver/${eventSchema}/jsonschema/1-0-0`,
         data: eventData
     });
   }
}

 function trackEvent(category, action, stringValue, property, floatValue, context){
     if(snowplowLoaded()){
        if(context) {
         snowplow('trackStructEvent', category, action, property, stringValue, floatValue, [{
            schema: "iglu:com.filedriver/uploaded_file_context/jsonschema/1-0-0",
            data: context
        }]);
        } else {
         snowplow('trackStructEvent', category, action, property, stringValue, floatValue);
        }
     }
 }

 function getSessionId() {
   //return '553e5011-9de4-49ff-b55a-835ffdc99a52'
    //return '9478d276-9a94-4267-957f-ec7be5952aed'
   //let cookieName = '_sp_id.0dc6';
   //let value = getCookieValue(cookieName);
   let value = getSnowplowCookie();
   if(value) {
      let sessionId = value.split('.')[5]; //The session Id is the 6th element in the array (0-based) splitted by '.'. Example value - "4df62d12-2f20-461f-a8c6-c9208dd2e0ae.1602752302.132.1606601666.1606595200.9478d276-9a94-4267-957f-ec7be5952aed"
      return sessionId;
   }
   return null;
 }

 function userId(){
  // let cookieName = '_sp_id.0dc6';
   let res = null;
   //let res = 'f15c4399-b0ef-44ff-be13-7f2b22f5f1d7'; 
    return function get(){
      if(res != null){
         return res;
      }
      let value = getSnowplowCookie();
     // let value = getCookieValue(cookieName);
     if(value) {
      let idx = value.indexOf('.');
      res = value.substring(0, idx);
     }
      return res;
    }
 }

 function reportGoogleAnalyticsConversion(conversionType){
    switch (conversionType) {
       case consts.GA_CONVERSION_TYPES.VERIFICATION_AND_WORK_EMAIL:
         gtag_report_conversion();
          break;
          case consts.GA_CONVERSION_TYPES.ENGAGED_USER:
            gtag_report_engaged_user_conversion();
             break;
          case consts.GA_CONVERSION_TYPES.ANSWERED_WORK:
            gtag_report_answered_work_conversion();
             break;
          case consts.GA_CONVERSION_TYPES.SIGNUP:
            gtag_report_signup();
             break;
         case consts.GA_CONVERSION_TYPES.SIGNUP_VISIT:
            gtag_report_signup_visit();  
         break;
         case consts.GA_CONVERSION_TYPES.EXTENSION_INSTALLED:
            gtag_report_extension_installed();  
            break;
       default:
          break;
    }
 }

 function invokeTrackableEvent(category, elementToTrack, value, cb){
    cb();
    trackEvent(category, elementToTrack, value);
 }

 function getCookieValue(cookieName) {
   var b = document.cookie.match('(^|;)\\s*' + cookieName + '\\s*=\\s*([^;]+)');
   return b ? b.pop() : '';
}

function getSnowplowCookie() {
   let cookie = document.cookie;
   if(cookie) {
      return cookie.split(';')
      .map(i => i.trim()).filter(c => c.startsWith('_sp_id'))[0].split('=')[1];
   } else {
      return null;
   }
}

function decodeBase64ToPojo(encodedString){
   if(encodedString && typeof encodedString !== 'undefined'){
      var decodedString = JSON.parse(atob(encodedString))
      return decodedString;
   }
}

function deleteFileFromLocalStorage(fileName) {
   let cachedFiles = localStorage.getItem(consts.LOCAL_STORAGE_KEYS.UPLOADED_FILES);
   let parsedFiles = JSON.parse(cachedFiles);
   let filteredFiles = parsedFiles && parsedFiles.value && parsedFiles.value.filter(i => i.name !== fileName);
   setLocalStorageItem(consts.LOCAL_STORAGE_KEYS.UPLOADED_FILES, filteredFiles, 7);
}

function setLocalStorageItem(key, value, ttlInDays) {
   // `item` is an object which contains the original value
   // as well as the time when it's supposed to expire
   var timestamp = new Date();
   var expirationTimeStamp = new Date();
   expirationTimeStamp.setHours( timestamp.getHours() + 24 * ttlInDays);
   let item = {
       value: value,
       expirationTimeStamp: expirationTimeStamp
   };

   localStorage.setItem(key, JSON.stringify(item));
}

function getLocalStorageItem(key) {
   let item = localStorage.getItem(key);
   
   // if the item doesn't exist, return null
   if (!item) return null;

   item = JSON.parse(item);
   // compare the expiry time of the item with the current time
   if (item.expiry && Date.now() > item.expiry) {
       // If the item is expired, delete the item from storage and return null
       localStorage.removeItem(key);

       return null;
   }
   
   return item.value;
}

function setSessionStorageItem(key, value) {
   sessionStorage.setItem(key, value);
}

function removeSessionStorageItem(key) {
   sessionStorage.removeItem(key);
}

function getSessionStorageItem(key) {
   let data = sessionStorage.getItem(key);
   return data;
}

function animateValue(updateState, start, end, duration) {
   let startTimestamp = null;
   const step = (timestamp) => {
     if (!startTimestamp) startTimestamp = timestamp;
     const progress = Math.min((timestamp - startTimestamp) / duration, 1);
     updateState(Math.floor(progress * (end - start) + start));
     if (progress < 1) {
       window.requestAnimationFrame(step);
     }
   };
   window.requestAnimationFrame(step);
 }

 function isWorkEmail(email) {
    //TODO: Add to this list as we discover more personal emails
    let blackList = ['gmail', 'hotmail', 'yahoo', 'aol', 'outlook', 'Zoho', 'icloud', 'msn']
    let res = true;
    blackList.forEach(domain => {
      if(email.includes(domain)) {
         res = false;
      }
    })
    return res;
 }

 function isEmail(email) {
   return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
 }

  function diff_minutes(dt2, dt1) 
 {
  var diff =(dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
 }

 function getFileType(fileName) {
    if(fileName){
      return fileName.substring(fileName.lastIndexOf('.')+1, fileName.length)
    }
 }

 function getRecaptchaToken(actionString, timeoutInmS) {
    let timeout = timeoutInmS || 10000;  //default to 10s timeout
    return new Promise((resolve, reject) => {
      const timer = setTimeout(() => {
         trackEvent('recaptcha', 'get-token', 'timeout');
         reject(new Error(`Promise timed out after ${timeout} ms`));
     }, timeout);
      grecaptcha.ready(() => {
         grecaptcha.execute('6Ld2MAYaAAAAAIjgG4yWizwpqFGO0fgAhq4ljRAH', {action: actionString})
         .then(function(token) {
            clearTimeout(timer);
            resolve(token);
          }).catch(error => {
            console.log(error); 
            clearTimeout(timer);
            trackEvent('recaptcha', 'get-token', 'failed');
            reject(error);
         });
      })
    })
 }

 async function wait(ms) {
   return new Promise((resolve) => setTimeout(resolve, ms));
 }

 function sendVerificationCode(userEmail, forceResend = false, verificationMethod, userPhone){
   async function sendCode(userDTO) {
         try {
             await restUtils.post('sendVerificationCode', userDTO, true);
         }
         catch(error){
             console.log(error);
         }
   }
 const getUserId = userId();
 const userIdFromCookie = getUserId();
 const sessionId = getSessionId();
 if (userId != '') {
     let userDTO = {
         userId: userIdFromCookie,
         userEmail: userEmail,
         sessionId: sessionId,
         forceResend: forceResend,
         verificationMethod: verificationMethod || consts.CODE_VERIFICATION_METHODS.EMAIL,
         userPhone: userPhone,
     }
     sendCode(userDTO);
    }
 }

 function undefineIsZero(value){
   return typeof value == 'undefined' ? 0 : value;
}

function clearAllLocalStorage() {
   localStorage.clear();
}

function clearTransferLocalStorage() {
   setLocalStorageItem(consts.LOCAL_STORAGE_KEYS.EMAIL_FROM,null, 6);
   setLocalStorageItem(consts.LOCAL_STORAGE_KEYS.EMAIL_RECIPIENTS, null, 6);
   setLocalStorageItem(consts.LOCAL_STORAGE_KEYS.EMAIL_MESSAGE, null, 6);
   setLocalStorageItem(consts.LOCAL_STORAGE_KEYS.UPLOADED_FILES, null, 6);
}

function scaleCanvas (canvas, width, height) {
   var w = canvas.width,
     h = canvas.height;
   if (width == undefined) {
     width = w;
   }
   if (height == undefined) {
     height = h;
   }
 
   var retCanvas = document.createElement('canvas');
   var retCtx = retCanvas.getContext('2d');
   retCanvas.width = width;
   retCanvas.height = height;
   retCtx.drawImage(canvas, 0, 0, w, h, 0, 0, width, height);
   return retCanvas;
 }

 function generateSnapshotFromDiv(divId) {
   return new Promise((resolve, reject) => {
     const element = document.getElementById(divId);
     html2canvas(element)
         .then(canvas => {
            canvas.toBlob(function(blob,) {
             //let image = canvas.toDataURL("image/jpeg", 1.0);
             resolve(blob);
           }, "image/jpeg", 1.0);
         })
         .catch(err => {
             console.log(err);
             reject(err);
         });
   });
 }

 async function generateBrandPreviewSnapshot() {
    let snapshotBlob = await generateSnapshotFromDiv('sitePreviewContainer');
    return snapshotBlob;
 }

 async function getUserCountryCode() {
   let res = await restUtils.getExternalUrl('https://ipapi.co/json/');
   let countryCode = res.data.country_code;
   return countryCode;
}

function getMockProgress(step, updateProgress) {
   let mockProgress = 0;
   let mockProgressInterval = null;
   function progressUpdater() {
       if(mockProgressInterval) {
          mockProgress = 0;
          updateProgress(0);
          clearInterval(mockProgressInterval)
       }
     mockProgressInterval = setInterval(() => {
        mockProgress += step;
        updateProgress(mockProgress)
        if(mockProgress === 100) {
         clearInterval(mockProgressInterval);
        }
      },1000); 
    //return mockProgress;
   }
   function clear() {
      mockProgress = 0;
      clearInterval(mockProgressInterval);
    }
    return {progressUpdater: progressUpdater, clear: clear};
 }

 function throttle (callback, limit) {
   var waiting = false;                      // Initially, we're not waiting
   return function () {                      // We return a throttled function
       if (!waiting) {                       // If we're not waiting
           callback.apply(this, arguments);  // Execute users function
           waiting = true;                   // Prevent future invocations
           setTimeout(function () {          // After a period of time
               waiting = false;              // And allow future invocations
           }, limit);
       }
   }
}

function reportHjUserAttributes() {
   const getUserId = userId();
   const userIdFromCookie = getUserId();
   const sessionId = getSessionId();
   let completedTransfers = getLocalStorageItem(consts.LOCAL_STORAGE_KEYS.COMPLETED_TRANSFERS) || {count: 0, lastTransferDate: new Date()};
   //var userId = your_user_id || null; // Replace your_user_id with your own if available.
   if(typeof window.hj === 'function' && userIdFromCookie && sessionId) {
      console.log(`**** sending hj attributes*** userId: ${userIdFromCookie}, sessionId: ${sessionId}, transfer count: ${completedTransfers.count}, last transfer: ${JSON.stringify(completedTransfers.lastTransferDate)}`)
      window.hj('identify', userIdFromCookie, {
         'Session Id': `${sessionId}`,
         'Completed transfers count': completedTransfers.count,
         'Last transfer date':  JSON.stringify(completedTransfers.lastTransferDate),
          // Add your own custom attributes here. Some EXAMPLES:
          // 'Signed up': '2019—06-20Z', // Signup date in ISO-8601 format.
          // 'Last purchase category': 'Electronics', // Send strings with quotes around them.
          // 'Total purchases': 15, // Send numbers without quotes.
          // 'Last purchase date': '2019-06-20Z', // Send dates in ISO-8601 format.
          // 'Last refund date': null, // Send null when no value exists for a user.
      });
   }
}

function checkIfExtensionIsInstalled() {
   var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
   const prodId = 'hiiahgelfnfpdcbfbihjdmpjcmglbbgc'; //real prod
   // eslint-disable-next-line no-undef
   //const devId = 'kjocakchmhfimhpogmacklbbidcdmdni';
   //const stagingId = 'gmkllglamfcfjdpakkbpcpacbjpdokke';
   
   return new Promise((resolve, reject) => {
     if(isChrome) {
       // eslint-disable-next-line no-undef
       if(chrome && chrome.runtime && typeof chrome.runtime.sendMessage === 'function') {
         // eslint-disable-next-line no-undef
         chrome.runtime.sendMessage(prodId, { message: "version" },
         function (reply) {
           if (!reply) {
             console.log('No extension found');
             resolve(false);
           }
           console.log('Extension version: ', reply.version);
           resolve(true);
         });
       } else {
         resolve(false)
       }
     } else {
       resolve(false)
     }

   })
 }

 function getChromeVersion() {
    if(typeof window !== 'undefined') {
      let ua = window.navigator.userAgent;
      let match = ua && ua.match(/(?:chrome|crios|crmo)\/(\d+(\.?_?\d+)+)/i)
      let version = (match && match.length > 0 && match[1]) || '';
      return parseFloat(version);
    }
 }

 function checkIfExtensionIsPinned() {
   var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
   let version = getChromeVersion();
   const MINIMAL_CHROME_VERSION = 91; //this feature is only enabled in chrome 91+
   const prodId = 'hiiahgelfnfpdcbfbihjdmpjcmglbbgc'; //real prod
   // eslint-disable-next-line no-undef
   //const devId = 'kjocakchmhfimhpogmacklbbidcdmdni';
   //const stagingId = 'gmkllglamfcfjdpakkbpcpacbjpdokke';
   const notSupportedResponse = {type: 'notSupported', isPinned: null}
   return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-undef
     if(isChrome && chrome.runtime && typeof chrome.runtime.sendMessage === 'function' && version >= MINIMAL_CHROME_VERSION) {
       // eslint-disable-next-line no-undef
       chrome.runtime.sendMessage(prodId, { message: "check-pinned" },
       function (reply) {
         if (!reply) {
           resolve(notSupportedResponse);
         }
         resolve(reply);
       });
     } else {
       resolve(notSupportedResponse)
     }
   })
 }

 function sendChromeExtensionMessage(message) {
   var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
     const prodId = 'hiiahgelfnfpdcbfbihjdmpjcmglbbgc'; //real prod
  // const devId = 'kjocakchmhfimhpogmacklbbidcdmdni';
   return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-undef
     if(isChrome && chrome.runtime && typeof chrome.runtime.sendMessage === 'function') {
       // eslint-disable-next-line no-undef
       chrome.runtime.sendMessage(prodId, message,
       function (reply) {
         if (!reply) {
           reject();
         }
         resolve(reply);
       });
     } else {
       reject();
     }
   })
 }

 function clearPackageDataFromLocalStorage() {
   setLocalStorageItem(consts.SELECTED_PACKAGE.PACKAGE_ID, null, 7);
   setLocalStorageItem(consts.SELECTED_PACKAGE.PRICE, null, 7);
   setLocalStorageItem(consts.SELECTED_PACKAGE.PAYMENT_SUCCEEDED, null, 7);
   setLocalStorageItem(consts.SELECTED_PACKAGE.PaidPackage, null, 7);
 }

 function reportGoogleAnalyticsEvent(category, action, timeout) {
// Sends the event to Google Analytics and
  // resubmits the form once the hit is done.
  // eslint-disable-next-line no-undef
  return new Promise((resolve, reject) => {
   setTimeout(() => resolve(), timeout || 1000); //defaults to 1s timeout
   ga('send', 'event', category, action, {
      hitCallback: function() {
         resolve();
      }
    });
  })
 }

 function initUserLeap(userId, userEmail) {
    if(UserLeap && typeof UserLeap === 'function') {
       // Ensures Sprig does not re-survey the same user and increases tracking accuracy
       UserLeap('setUserId', userId);
       // Sets visitor's email address to enable email surveys
       UserLeap('setEmail', userEmail);
    }
 }

 function trackUserLeapEvent(event) {
   if(UserLeap && typeof UserLeap === 'function') {
      UserLeap('track', event);
   }
 }

 export default {
    arrayUniqueNames,
    bytesToSize,
    trackEvent,
    invokeTrackableEvent,
    userId,
    getCookieValue,
    decodeBase64ToPojo,
    reportGoogleAnalyticsConversion,
    setLocalStorageItem,
    getLocalStorageItem,
    deleteFileFromLocalStorage,
    animateValue,
    bytesToKbs,
    trackSelfDescribedEvent,
    trackFileAdded,
    trackUploadCancelled,
    trackFileRemoved,
    trackFileDownloaded,
    trackFileUploadStarted,
    trackFileUploadCompleted,
    trackFormStepChanged,
    trackUserEmailAdded,
    trackRecipientAdded,
    trackUploadCompleted,
    isWorkEmail,
    diff_minutes,
    getSessionId,
    getFileType,
    trackWorkQuestionAnsweredEvent,
    getRecaptchaToken,
    wait,
    sendVerificationCode,
    isEmail,
    trackPageView,
    setSessionStorageItem,
    getSessionStorageItem,
    undefineIsZero,
    clearAllLocalStorage,
    removeSessionStorageItem,
    scaleCanvas,
    generateSnapshotFromDiv,
    generateBrandPreviewSnapshot,
    getUserCountryCode,
    clearTransferLocalStorage,
    getMockProgress,
    throttle,
    reportHjUserAttributes,
    checkIfExtensionIsInstalled,
    checkIfExtensionIsPinned,
    getChromeVersion,
   clearPackageDataFromLocalStorage,
   reportGoogleAnalyticsEvent,
   initUserLeap,
   trackUserLeapEvent,
   sendChromeExtensionMessage
 }
